<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <div style="display: flex; justify-content: center;">
                <v-btn text x-small v-bind="attrs" v-on="on" class="my-2 list">
                    {{ getStatus(value.status) }}
                    <v-icon x-small class="ml-1">
                        mdi-chevron-down
                    </v-icon>
                </v-btn>
            </div>
        </template>
        <v-list>
            <v-list-item v-if="!isFinalizado" @click="updateStatus(value.status, !value.pronto)">
                <v-list-item-title>
                    <v-icon class="mr-2">
                        mdi-thumb-{{ value.pronto ? 'down' : 'up' }}
                    </v-icon>
                    <span>
                        {{ `Sinalizar como ${value.pronto ? 'Pendente' : 'Pronto'}` }}
                    </span>
                </v-list-item-title>
            </v-list-item>

            <v-list-item
                v-if="showEmPreparacao"
                @click="updateStatus('em_preparacao')"
            >
                <v-list-item-title>
                    <v-icon class="mr-2">mdi-chef-hat</v-icon>
                    <span>Em Preparação</span>
                </v-list-item-title>
            </v-list-item>

            <v-list-item
                v-if="showSaiuParaEntrega"
                @click="updateStatus('saiu_para_entrega')"
            >
                <v-list-item-title>
                    <v-icon class="mr-2">mdi-moped</v-icon>
                    <span>Saiu p/ Entrega</span>
                </v-list-item-title>
            </v-list-item>

            <v-list-item
                v-if="showAguardandoRetirada"
                @click="updateStatus('aguardando_retirada')"
            >
                <v-list-item-title>
                    <v-icon class="mr-2">mdi-package-variant-closed-check</v-icon>
                    <span>Aguardando Retirada</span>
                </v-list-item-title>
            </v-list-item>

            <v-list-item
                v-if="showReenviarEntregador"
                @click="resendToEntregador()"
            >
                <v-list-item-title>
                    <v-icon class="mr-2">mdi-whatsapp</v-icon>
                    <span>Reenviar p/ Entregador</span>
                </v-list-item-title>
            </v-list-item>

            <v-list-item
                v-if="showConcluido"
                @click="updateStatus('concluido')"
            >
                <v-list-item-title>
                    <v-icon class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
                    <span>Concluído</span>
                </v-list-item-title>
            </v-list-item>

            <v-list-item
                v-if="showCancelar"
                @click="updateStatus('cancelado')"
            >
                <v-list-item-title>
                    <v-icon class="mr-2">mdi-close</v-icon>
                    <span>Cancelar</span>
                </v-list-item-title>
            </v-list-item>

            <v-divider/>

            <v-list-item
                v-if="hasFiscalModule && value.nota_id && isNotaAprovada(value.nota)"
                @click="imprimirNfce"
            >
                <v-list-item-title>
                    <v-icon class="mr-2">mdi-printer</v-icon>
                    <span>
                        Imprimir Nota
                    </span>
                </v-list-item-title>
            </v-list-item>

            <v-list-item
                v-else-if="hasFiscalModule && !isNotaAprovada()"
                @click="emitirNfce"
            >
                <v-list-item-title>
                    <v-icon class="mr-2">mdi-note</v-icon>
                    <span>
                        Emitir Nota
                    </span>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import { hasModule } from '@/utils/module';
import { printDanfe } from '@/utils/utils';

export default {
    name: 'OptionsList',

    props: ['value'],

    computed: {
        isFinalizado() {
            return this.value.finalizado;
        },

        hasFiscalModule() {
            return hasModule('FISCAL');
        },

        showEmPreparacao() {
            return !this.isFinalizado &&
                this.value.entrega != 3 &&
                ['pago', 'confirmado'].includes(this.value.status);
                // this.value.status === 'confirmado';
        },

        showSaiuParaEntrega() {
            return !this.isFinalizado &&
                this.value.entrega == 2 &&
                ['em_preparacao', 'confirmado', 'pago'].includes(this.value.status);
        },

        showAguardandoRetirada() {
            return !this.isFinalizado &&
                this.value.entrega == 1 &&
                ['em_preparacao', 'confirmado', 'pago'].includes(this.value.status);
        },

        showReenviarEntregador() {
            return !this.isFinalizado &&
                this.value.entrega == 2 &&
                this.value.status === 'saiu_para_entrega';
        },

        showCancelar() {
            return !this.isFinalizado;
        },

        showConcluido() {
            return !this.isFinalizado && this.value.status != 'concluido';
        },
    },

    methods: {
        async updateStatus(status, pronto = this.value.pronto) {
            if (status === 'cancelado') {
                const confirm = await this.$root.$confirm(
                    'Confirmação',
                    'Deseja realmente cancelar?',
                    { color: 'red darken-1' }
                );

                if (!confirm) {
                    return;
                }
            }
            this.$emit('onUpdateStatus', { id: this.value.id, status, pronto });
        },

        emitirNfce() {
            this.$root.$emit('loading', true);
            this.$http.post('fiscal/emissao-nfce', { pedido_id: this.value.id })
                .then(resp => {
                    if (resp.data.type == 'warning') {
                        this.notify(resp.data.msg, 'warning');
                        return;
                    }
                    const data = resp.data.data;

                    if (data?.nfce?.status === 'aprovado') {
                        this.notify(`Aprovado, ${data.nfce.motivo}`);
                    } else {
                        this.notify(`${data.nfce.status} ${data.nfce.motivo}`, 'warning');
                    }
                })
                .catch(() => {
                    this.notify('Não foi possivel concluir a operação', 'warning');
                }).finally(() => (this.$root.$emit('loading', false)));
        },

        imprimirNfce() {
            printDanfe(this.value.nota.danfe);
        },

        isNotaAprovada(nota) {
            return nota?.status === 'aprovado';
        },

        resendToEntregador() {
            this.$http.post(`pedidos/${this.value.id}/resend-entregador`).then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }
                this.notify('Enviado com sucesso!');
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            });
        },

        getStatus(status) {
            let str = '';
            (status == 'aguardando_pagamento') && (str = 'Aguard. Pagamento');
            (status == 'pago') && (str = 'Pago Online');
            (status == 'recebido') && (str = 'Recebido');
            (status == 'cancelado') && (str = 'Cancelado');
            (status == 'confirmado') && (str = 'Confirmado');
            (status == 'em_preparacao') && (str = 'Em Preparação');
            (status == 'saiu_para_entrega') && (str = 'Saiu p/ Entrega');
            (status == 'aguardando_retirada') && (str = 'Aguard. Retirada');
            (status == 'concluido') && (str = 'Concluído');
            return str;
        },
    },
}
</script>

<style scope>
.list {
    border: 1px solid #fff;
}
</style>
